import * as React from "react";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";

import { csrfToken } from "utils/csrfToken";

import Logo from "components/Logo";
import Message from "components/Message";
import Button from "components/Button";
import { IconGitHub } from "components/Icons";

interface ILoginProps {
  redirect?: string | null;
  error?: string;
  invitationKey?: string | null;
  invitationTo?: string;
  invitationRole?: string;
  invitationBy?: string;
}

const Login = ({
  redirect,
  error,
  invitationKey,
  invitationTo,
  invitationRole,
  invitationBy,
}: ILoginProps) => {
  useEffect(() => {
    sessionStorage.setItem("lastLoginURL", window.location.href);
  }, []);

  const hasInvitation = invitationKey && invitationTo && invitationRole;

  return (
    <div className="absolute left-0 top-0 w-full min-h-full p-8 flex items-center justify-center">
      <div className="w-full max-w-[500px] p-8 bg-white/5">
        <Logo className="w-64 max-w-full mx-auto mb-8" />

        {hasInvitation && (
          <Message type="success">
            <p>
              {invitationBy && invitationBy.length > 0 ? (
                <>
                  <strong>{invitationBy}</strong> has invited you
                </>
              ) : (
                "You have been invited"
              )}{" "}
              to access license <strong>{invitationTo}</strong> as{" "}
              {"aeio".includes(invitationRole[0].toLowerCase()) ? "an " : "a "}{" "}
              <strong>{invitationRole}</strong>.
            </p>
            <p>Please login to accept the invitation.</p>
          </Message>
        )}

        {error && <Message type="error">{error}</Message>}

        <form method="post" action="/login">
          <input type="hidden" name="csrf-token" value={csrfToken()} />
          {redirect && <input type="hidden" name="redirect" value={redirect} />}
          {hasInvitation && <input type="hidden" name="invitation-key" value={invitationKey} />}
          <Button type="submit" accented className="w-full" icon={IconGitHub} href="/">
            Login with GitHub
          </Button>
        </form>
      </div>
    </div>
  );
};

const container = document.getElementById("app");

if (container) {
  const params = new URLSearchParams(window.location.search);
  const redirect = params.get("redirect");
  const error = container.dataset.error;
  const invitationKey = params.get("inv");
  const invitationTo = container.dataset.invitationTo;
  const invitationRole = container.dataset.invitationRole;
  const invitationBy = container.dataset.invitationBy;

  createRoot(container).render(
    <Login
      redirect={redirect}
      error={error}
      invitationKey={invitationKey}
      invitationTo={invitationTo}
      invitationRole={invitationRole}
      invitationBy={invitationBy}
    />,
  );
}
